import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  EditButton,
  Responsive,
  Filter
} from 'react-admin';
import MyDateTimeField from '../MyDateTimeField';

const CcyRateFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label='Account'
      source='account_id'
      reference='accounts'
      alwaysOn
    >
      <SelectInput optionText='account_name' />
    </ReferenceInput>
  </Filter>
);

const CcyRateList = props => (
  <List
    {...props}
    sort={{ field: 'ccy', order: 'ASC' }}
    perPage={10}
    exporter={false}
    bulkActionButtons={false}
    filters={<CcyRateFilter />}
  >
    <Responsive
      xsmall={
        <SimpleList
          primaryText={record => record.ccy}
          secondaryText={record => 'As of: ' + record.updated_at}
          tertiaryText={record => record.rate.toLocaleString()}
        />
      }
      small={
        <Datagrid rowClick='edit'>
          <ReferenceField source='account_id' reference='accounts'>
            <TextField source='account_name' />
          </ReferenceField>
          <TextField source='ccy' />
          <NumberField
            source='rate'
            options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }}
          />
          <MyDateTimeField source='updated_at' showTime locales='de-CH' />
        </Datagrid>
      }
    />
  </List>
);

export default CcyRateList;
