import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  DisabledInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  LongTextInput,
  DateInput,
  BooleanInput,
  Create,
  Responsive
} from 'react-admin';

export const AuthorizedAccountList = props => (
  <List {...props}>
    <Datagrid>
      <ReferenceField source='user_id' reference='users'>
        <TextField source='username' />
      </ReferenceField>
      <ReferenceField source='account_id' reference='accounts'>
        <TextField source='account_name' />
      </ReferenceField>
      <BooleanField source='read' />
      <BooleanField source='write' />
      <BooleanField source='execute' />
      <EditButton />
    </Datagrid>
  </List>
);

export const AuthorizedAccountEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source='id' />
      <ReferenceField source='user_id' reference='users'>
        <TextField source='username' />
      </ReferenceField>
      <ReferenceField source='account_id' reference='accounts'>
        <TextField source='account_name' />
      </ReferenceField>
      <BooleanInput label='Read ?' source='read' />
      <BooleanInput label='Write ?' source='write' />
      <BooleanInput label='Execute ?' source='execute' />
    </SimpleForm>
  </Edit>
);

export const AuthorizedAccountCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source='user_id' reference='users'>
        <SelectInput optionText='username' />
      </ReferenceInput>
      <ReferenceInput source='account_id' reference='accounts'>
        <SelectInput optionText='account_name' />
      </ReferenceInput>
      <BooleanInput label='Read ?' source='read' />
      <BooleanInput label='Write ?' source='write' />
      <BooleanInput label='Execute ?' source='execute' />
    </SimpleForm>
  </Create>
);
