import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  Filter,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  EditButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  Responsive
} from 'react-admin';
import MyDateTimeField from '../MyDateTimeField';

const PositionsFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label='Account'
      source='account_id'
      reference='accounts'
      alwaysOn
    >
      <SelectInput optionText='account_name' />
    </ReferenceInput>
  </Filter>
);

const PositionList = props => (
  <List
    {...props}
    sort={{ field: 'instrument', order: 'ASC' }}
    perPage={10}
    exporter={false}
    bulkActionButtons={false}
    filter={{ position_size: '0|<>' }}
    filters={<PositionsFilter />}
  >
    <Responsive
      xsmall={
        <SimpleList
          primaryText={record => record.instrument}
          secondaryText={record => 'As of: ' + record.updated_at}
          tertiaryText={record => record.unrealized_pnl.toLocaleString()}
        />
      }
      small={
        <Datagrid rowClick='edit'>
          <ReferenceField source='account_id' reference='accounts'>
            <TextField source='account_name' />
          </ReferenceField>
          <TextField source='instrument' />
          <NumberField source='position_size' />
          <NumberField source='market_price' />
          <NumberField source='avg_cost' />
          <NumberField
            source='unrealized_pnl'
            options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          />
          <NumberField
            source='bccy'
            options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          />
          <MyDateTimeField source='updated_at' showTime locales='de-CH' />
        </Datagrid>
      }
    />
  </List>
);

export default PositionList;
