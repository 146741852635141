import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';

import positions from '../positions';

const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
};

const LinkToRelatedPositions = ({ classes, record, translate }) => (
    <Button
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/positions',
            search: stringify({
                page: 1,
                perPage: 10,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ account_id: record.id }),
            }),
        }}
        className={classes.link}
    >
        <positions.icon className={classes.icon} />
        {translate('resources.accounts.fields.positions')}
    </Button>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(LinkToRelatedPositions);
