import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardReader from '@material-ui/core/CardHeader';

export default ({ sizeValue }) => (
  <Card>
    <CardReader title='Status' />
    <CardContent>
      You are user: {localStorage.getItem('username')}
      <br />
      Size: {sizeValue}
      {/* <br />
      Inner-Window: X: {window.innerWidth} Y: {window.innerHeight}
      <br />
      Outer-Window: X: {window.outerWidth} Y: {window.outerHeight} */}
    </CardContent>
  </Card>
);
