import React from 'react';
import { List, SimpleList, Datagrid, TextField, Responsive } from 'react-admin';
import MyDateTimeField from '../MyDateTimeField';

const SystemsAliveList = props => (
  <List
    {...props}
    sort={{ field: 'updated_at', order: 'DESC' }}
    perPage={10}
    exporter={false}
    bulkActionButtons={false}
  >
    <Responsive
      xsmall={
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => record.ip_address}
          tertiaryText={record => record.updated_at}
        />
      }
      small={
        <Datagrid rowClick='edit'>
          <TextField source='name' />
          <TextField source='ip_address' />
          <MyDateTimeField source='updated_at' />
        </Datagrid>
      }
    />
  </List>
);

export default SystemsAliveList;
