import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  RadioButtonGroupInput,
  Create
} from 'react-admin';
import { EditButton } from 'ra-ui-materialui/lib/button';
//import MyUrlField from './MyUrlField';

export const UserList = props => (
  <List
    {...props}
    sort={{ field: 'username', order: 'ASC' }}
    perPage={10}
    exporter={false}
  >
    <Datagrid>
      <TextField source='username' />
      <EmailField source='email' />
      <TextField source='role' />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <DisabledInput source='id' />
      <TextInput source='username' />
      <TextInput source='email' />
      <RadioButtonGroupInput
        source='role'
        choices={[
          { id: 'T', name: 'Technical User, e.g. Dataprovider' },
          { id: 'U', name: 'User, access to limited functionality' },
          { id: 'A', name: 'Administrator, access to all accounts' },
          { id: 'S', name: 'Super-Admin, access to everything' }
        ]}
      />
      <TextInput source='password' />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <TextInput source='username' />
      <TextInput source='email' />
      <RadioButtonGroupInput
        source='role'
        choices={[
          { id: 'T', name: 'Technical User, e.g. Dataprovider' },
          { id: 'U', name: 'User, access to limited functionality' },
          { id: 'A', name: 'Administrator, access to all accounts' },
          { id: 'S', name: 'Super-Admin, access to everything' }
        ]}
      />
      <TextInput source='password' />
    </SimpleForm>
  </Create>
);
