const MyDateTimeField = function({ record = {}, source, classes }) {
  let DateString = record[source].replace(' ', 'T');

  let a = DateString.split(/[^0-9]/);

  let D = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);

  let ret = D.toLocaleString('de-CH');

  return ret;
};

export default MyDateTimeField;
