import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  Filter,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Responsive
} from 'react-admin';

const ExecutionsFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label='Account'
      source='account_id'
      reference='accounts'
      alwaysOn
    >
      <SelectInput optionText='account_name' />
    </ReferenceInput>
  </Filter>
);

const ExecutionList = props => (
  <List
    {...props}
    sort={{ field: 'execution_time', order: 'DESC' }}
    perPage={10}
    exporter={false}
    bulkActionButtons={false}
    filters={<ExecutionsFilter />}
  >
    <Responsive
      xsmall={
        <SimpleList
          primaryText={record => record.instrument}
          secondaryText={record => record.execution_time}
          tertiaryText={record => record.realized_pnl_bccy.toLocaleString()}
        />
      }
      small={
        <Datagrid rowClick='edit'>
          <ReferenceField source='account_id' reference='accounts'>
            <TextField source='account_name' />
          </ReferenceField>
          <TextField source='instrument' />
          <NumberField source='execution_size' />
          <NumberField source='market_price' />
          <NumberField
            source='commission'
            options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
          />
          />
          <TextField source='commission_ccy' />
          <NumberField
            source='realized_pnl'
            options={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
          />
          <NumberField
            source='realized_pnl_bccy'
            options={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
          />
          <TextField source='execution_time' />
        </Datagrid>
      }
    />
  </List>
);

export default ExecutionList;
