import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardReader from '@material-ui/core/CardHeader';
import FBXlogo from '../FinbrixLogo_TopPage.svg';

export default () => (
  <Card>
    <CardReader title='Welcome to the FINBRIX-Info App!' />
    <CardContent>
      <img src={FBXlogo} className='FBX-logo' alt='FBX-logo' width='250px' />
      <br />
      Version: 1.1.0
      <br />
      All Data is neartime and read-only.
    </CardContent>
  </Card>
);
