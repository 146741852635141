import React, { Component } from 'react';
import {
  fetchUtils,
  Admin,
  Resource,
  ListGuesser,
  EditGuesser
} from 'react-admin';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import accounts from './accounts';
import positions from './positions';
import executions from './Executions';
import ccyrates from './ccyrates';
import SystemsAlive from './SystemsAlive';

import {
  AuthorizedAccountList,
  AuthorizedAccountEdit,
  AuthorizedAccountCreate
} from './AuthorizedAccounts';
import { AuthorizedFunctionList } from './AuthorizedFunctions';
import { UserList, UserEdit, UserCreate } from './users';
import jsonServerProvider from 'ra-data-json-server';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import FaceIcon from '@material-ui/icons/Face';
// import { createMuiTheme } from '@material-ui/core/styles';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Token ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  'https://connect.finbrix.net/api',
  //'http://connect.finbrix.local/api',
  httpClient
);

const i18nProvider = locale => {
  if (locale === 'de') {
    return import('./i18n/de').then(messages => messages.default);
  }

  // Always fallback on english
  return englishMessages;
};

class App extends Component {
  state = {};

  render() {
    return (
      <Admin
        title='FINBRIX Info'
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        dashboard={Dashboard}
        appLayout={Layout}
        authProvider={authProvider}
        locale='en'
        i18nProvider={i18nProvider}
      >
        {permissions => [
          <Resource name='accounts' {...accounts} />,
          <Resource name='positions' {...positions} />,
          <Resource name='executions' {...executions} />,
          <Resource name='ccyrates' {...ccyrates} />,
          permissions === 'S' ? (
            <Resource
              name='users'
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
              icon={FaceIcon}
            />
          ) : null,
          permissions === 'S' ? (
            <Resource
              name='account_user'
              options={{ label: 'Account Users' }}
              list={AuthorizedAccountList}
              edit={AuthorizedAccountEdit}
              create={AuthorizedAccountCreate}
              icon={MoneyIcon}
            />
          ) : null,
          permissions === 'S' ? (
            <Resource
              name='authorized_functions'
              list={ListGuesser}
              icon={MoneyIcon}
            />
          ) : null,
          permissions === 'S' ? (
            <Resource
              name='systems/alive'
              options={{ label: 'Systems Alive' }}
              {...SystemsAlive}
            />
          ) : null
        ]}
      </Admin>
    );
  }
}

export default App;
