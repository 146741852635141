import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request('https://connect.finbrix.net/api/user/login', {
      //const request = new Request('http://connect.finbrix.local/api/user/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, role }) => {
        localStorage.setItem('username', username);
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        return Promise.resolve();
      });
  }

  //called when user clocked log out button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  // called when the API returns error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  //called when user navigates to new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role');
    //console.info('Role: ' + role);
    //console.info(params);
    return role ? Promise.resolve(role) : Promise.reject();
  }

  return Promise.reject('Unknown method');
};
