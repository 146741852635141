import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  EditButton,
  Responsive
} from 'react-admin';
import MyDateTimeField from '../MyDateTimeField';

import LinkToRelatedPositions from './LinkToRelatedPositions';

const AccountList = props => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <Responsive
      xsmall={
        <SimpleList
          primaryText={record => record.account_name}
          secondaryText={record => 'Pos: ' + record.CtrPos}
          tertiaryText={record =>
            record.base_ccy + ' ' + record.net_liquidation.toLocaleString()
          }
        />
      }
      small={
        <Datagrid>
          <TextField source='account_name' />
          <NumberField source='CtrPos' />
          <NumberField
            source='net_liquidation'
            options={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
          />
          <TextField source='base_ccy' />
          <LinkToRelatedPositions />
          <MyDateTimeField source='updated_at' showTime locales='de-CH' />
        </Datagrid>
      }
    />
  </List>
);

export default AccountList;
